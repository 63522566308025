import(/* webpackMode: "eager", webpackExports: ["ClientClerkProvider"] */ "/vercel/path0/my-project/node_modules/@clerk/nextjs/dist/esm/app-router/client/ClerkProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["AuthenticateWithRedirectCallback","ClerkLoaded","ClerkLoading","RedirectToCreateOrganization","RedirectToOrganizationProfile","RedirectToSignIn","RedirectToSignUp","RedirectToUserProfile"] */ "/vercel/path0/my-project/node_modules/@clerk/nextjs/dist/esm/client-boundary/controlComponents.js");
;
import(/* webpackMode: "eager", webpackExports: ["useAuth","useClerk","useEmailLink","useOrganization","useOrganizationList","useSession","useSessionList","useSignIn","useSignUp","useUser"] */ "/vercel/path0/my-project/node_modules/@clerk/nextjs/dist/esm/client-boundary/hooks.js");
;
import(/* webpackMode: "eager", webpackExports: ["CreateOrganization","GoogleOneTap","OrganizationList","OrganizationProfile","OrganizationSwitcher","SignIn","SignInButton","SignInWithMetamaskButton","SignOutButton","SignUp","SignUpButton","UserButton","UserProfile"] */ "/vercel/path0/my-project/node_modules/@clerk/nextjs/dist/esm/client-boundary/uiComponents.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/my-project/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.js\",\"import\":\"Manrope\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/my-project/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.js\",\"import\":\"Manrope\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"variable\":\"--font-manrope\",\"display\":\"swap\"}],\"variableName\":\"rubik\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/my-project/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/my-project/src/app/fonts.css");
;
import(/* webpackMode: "eager", webpackExports: ["CSPostHogProvider"] */ "/vercel/path0/my-project/src/app/providers.js");
